import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import Player from '@vimeo/player'

const PlayerWrapper = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
  margin: 2rem auto;
`

const VideoPlayer = ({ videoId }) => {
  const playerRef = useRef(null)
  useEffect(() => {
    const player = new Player(playerRef.current)
  }, [])
  return (
    <PlayerWrapper>
      <iframe
        src={`https://player.vimeo.com/video/${videoId}?byline=false&title=false&portrait=false&=false&autoplay=false&dnt=true`}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: 100 + '%',
          height: 100 + '%',
        }}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        ref={playerRef}
      ></iframe>
    </PlayerWrapper>
  )
}

export default VideoPlayer
